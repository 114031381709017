<script lang="ts" setup>
import { nextTick } from "#imports";

import { toast } from "vue3-toastify";

const { t } = useT();
const { isDesktop, isAndroid } = useDevice();

withDefaults(
	defineProps<{
		enabledPopup?: boolean;
	}>(),
	{
		enabledPopup: true
	}
);
const { isOpen } = useModals();

const toastOptions = {
	theme: toast.THEME.DARK,
	position: toast.POSITION.TOP_CENTER
};
const {
	unreadNotifications,
	readNotifications,
	isActiveAnimation,
	showNotificationPopup,
	handleClickNotificationMessage,
	setNotificationsStatusAsRead,
	updateNotificationsStatusAsRead,
	openPopup,
	switchNcSocket
} = useNotificationCenter({ isOpen, toastOptions, autoSocketSubscription: false });

onMounted(async () => {
	await nextTick();
	await useAsyncFetch({
		path: "/rest/request/player-activity/",
		method: "post",
		fetchOptions: () => ({
			onResponse: () => {
				switchNcSocket();
			}
		})
	});
});
</script>
<template>
	<div
		class="notification-center"
		data-tid="notification-center"
		:class="{ active: isActiveAnimation }"
		@click="openPopup"
	>
		<ABadge v-if="unreadNotifications.length">
			<AText variant="texas">{{ unreadNotifications.length }}</AText>
		</ABadge>
		<NuxtIcon :class="['icon', { 'is-active': isActiveAnimation || false }]" name="20/mail" filled />
		<AText v-if="!isDesktop" as="div" variant="topeka" class="text-conakry">
			{{ t("Notifications") }}
		</AText>
		<ONotificationCenterPopup
			v-if="showNotificationPopup && enabledPopup"
			:class="{ 'is-android': isAndroid }"
			:unread-notifications="unreadNotifications"
			:read-notifications="readNotifications"
			@set-status-read="setNotificationsStatusAsRead"
			@click-on-message="handleClickNotificationMessage"
			@update-notification-status="updateNotificationsStatusAsRead"
			@close-popup="showNotificationPopup = false"
		/>
	</div>
</template>
<style lang="scss">
@include media-breakpoint-down(lg) {
	body:has(.notification-popup) {
		overflow: hidden;
	}
}
</style>
<style scoped lang="scss">
.notification-center {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: gutter(3);
	z-index: 9;

	@include media-breakpoint-down(lg) {
		flex-direction: column;
		gap: 2px;
		margin-right: gutter(0);
	}

	&:has(.is-android):deep(.popper) {
		@include media-breakpoint-down(lg) {
			top: 64px !important;

			@media (orientation: landscape) {
				top: 0 !important;
			}
		}
	}

	&:deep(.popper) {
		background-color: var(--cacem);
		border: 1px solid var(--conakry);
		z-index: 10;
		border-radius: 12px;
		padding: 0;
		transform: none !important;
		left: auto !important;
		right: -180px !important;
		top: calc(100% + 20px) !important;
		overflow: hidden;

		@include media-breakpoint-down(lg) {
			position: fixed !important;
			left: 0 !important;
			top: 0 !important;
			height: 100dvh;
			will-change: height;
			right: auto !important;
			width: 100%;
			max-width: 100%;
			border-radius: 0;

			@media (orientation: landscape) {
				top: 0 !important;
				height: 100dvh;
			}
		}

		&:hover {
			background-color: var(--cacem);
		}
	}

	.badge {
		position: absolute;
		top: 0;
		right: -6px;
		z-index: 1;

		@include media-breakpoint-down(lg) {
			top: -4px;
			right: 6px;
		}
	}

	& > span {
		display: inline-flex;
	}
}

.icon {
	font-size: 24px;

	@include media-breakpoint-down(lg) {
		display: inline-flex;
		font-size: 22px;
		line-height: 1;
		height: 20px;
	}

	&:deep(svg) {
		margin-bottom: 0;
	}

	&.is-active {
		animation: shake 4s infinite;
	}
}
@keyframes shake {
	0%,
	80%,
	100% {
		transform: rotate(0deg);
	}
	85% {
		transform: rotate(-10deg);
	}
	95% {
		transform: rotate(10deg);
	}
}
</style>
